<template>
  <div class="header_area headerTop" >
    <div class="header_top">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <div class="rightbox d-flex align-items-center" :class="device !== 'desktop' && 'm-auto'">
            <div class="box d-flex align-items-stretch" v-if="device === 'desktop'">
              <div class="footer-social">
                <ul class="list-inline d-flex">
                  <li class="list-inline-item m-0 home-icon" v-if="isFCLAgent && currentPage !== 'pending-info'">
                    <a href="/external-booking" onclick="return false;" @click="gotoExternalPage" title="External Booking">
                      <i class="fas fa-th-list"></i>
                    </a>
                  </li>
                  <li class="ml-3 home-icon" v-if="isFCLAgent && currentPage !== 'booking-cancel'">
                    <a href="/cancel-booking" onclick="return false;" @click="gotoCancelPage" title="Cancel Booking">
                      <i class="fa fa-ban"></i>
                    </a>
                  </li>
                  <li class="ml-3 home-icon" v-if="isFCLAgent">
                    <a href="#" onclick="return false;" @click="showBookingCheckModal" title="Check Booking">
                      <i class="fa fa-check-circle"></i>
                    </a>
                  </li>
                  <li v-if="agencyUser" style="align-self: center;" class="ml-1">
                    <b-button @click="agencyLogout" variant="outline-light" size="sm" class="w-100">{{$t('login.log-off') }}</b-button>
                  </li>
                  <li class="list-inline-item m-0 home-icon" v-if="isNotHomePage">
                    <router-link :to="logoURL">
                      <i class="fas fa-home"></i>
                    </router-link>
                  </li>

                  <li class="list-inline-item" v-if="showInstagramLink">
                    <a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fa-brands fa-instagram"></i></a>
                  </li>
                  <li class="list-inline-item" v-if="showFacebookLink">
                    <a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li class="list-inline-item" v-if="showTwitterLink">
                    <a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-x-twitter"></i></a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="box d-flex align-items-stretch" v-if="device === 'desktop' && showFacebookLink"><a class="" :href="facebookLink"><p>{{ $t("top-header.follow-us") }} <i class="fa-solid fa-gear"></i></p></a></div>

            <div class="box d-flex align-items-stretch" v-if="device === 'desktop' && !isFCLAgent">
              <a class="w-100" :href="`tel:${phoneNumber}`" > <p>{{ phoneNumber }}
                <img src="assets/img/phone2.png" alt="phone"> {{ $t("top-header.vacation-in-israel") }}  </p></a>
            </div>

            <div class="box d-flex align-items-stretch" v-if="device === 'desktop' && showVacationPhone && !isFCLAgent">
              <a class="w-100" :href="`tel:${whiteLabel.vacationPhoneNumber}`" >
                <p>{{ whiteLabel.vacationPhoneNumber }}: <img src="assets/img/phone2.png" alt="phone2"> {{ $t("top-header.vacation-abroad") }} </p></a>
            </div>

          </div>
        </div>
      </div>
    </div>
    <b-modal id="checkTzNumber" centered :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true" :ok-only="true">
      <template >
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t("check-agent-booking.check-id-title") }}</h3>
        </div>
      </template>
      <template>
        <div class="w-100">

          <b-form style="display:flex;" @submit.stop.prevent>
            <b-input-group class="m-0">
              <b-input-group-prepend is-text>
                <i class="fa fa-user"></i>
              </b-input-group-prepend>
              <b-form-input id="tz-number" :placeholder="$t('check-agent-booking.tz-number')" v-model="tzNumber"></b-form-input>
            </b-input-group>
            <b-button variant="primary" @click="requestTzNumber">{{$t('check-agent-booking.check')}}</b-button>
          </b-form>

          <div v-if="isLoading && !tzNumberCheckResult" class="w-100 text-center my-5"><b-spinner large></b-spinner></div>

          <div v-if="tzNumberCheckResult" :class="`${lang}-result-body`">
            <b-alert :class="`${lang}-alert`" show dismissible variant="success" v-if="tzNumberCheckResult.isApproved" class="mt-4"><i class="fa fa-check"></i>{{ $t('check-agent-booking.approved') }}</b-alert>
            <b-alert :class="`${lang}-alert`" show dismissible variant="danger" v-else><i class="fa fa-check"></i>{{ $t('check-agent-booking.not-approved') }}</b-alert>
            <p v-for="(item, inx) in tzNumberCheckResult.usersHistoryList" :key="inx">
              <span v-if="item.status" style="color: green;">{{ $t('check-agent-booking.redemption') }} {{$t('check-agent-booking.subsidy')}} {{ item.confirmationNum }} {{ item.year }}</span>
              <span v-if="!item.status" style="color: red;">{{ $t('check-agent-booking.redemption') }} {{$t('check-agent-booking.canceled')}} {{ item.confirmationNum }} {{ item.year }}</span>
            </p>
          </div>

        </div>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="ok();">
          {{ $t("booking.ok") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  // BDropdown,
  // BDropdownItem,
  BForm, BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BAlert,
  BSpinner,
} from 'bootstrap-vue';
import TemplateTopHeader from './TemplateTopHeader';

export default {
  name: 'TopHeaderTheme1',
  mixins: [TemplateTopHeader],
  components: {
    BAlert,
    BButton,
    // BDropdown,
    // BDropdownItem,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BSpinner,
    // ContentWrapper: () => import('@/components/content/ContentWrapper')
  },
};
</script>
<style scoped>
li.home-icon {
  height: 24px;
  border-right: none !important;
}
li.home-icon > a {
  font-size: 1.2rem;
}
li.phone-number-rtl {
  direction: ltr;
}
ul {
  font-family: "Poppins", sans-serif;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.list-inline {
  padding-right: 0;
}
.landing-part .logo {
  max-width: 230px;
  float: left;
  margin-left: 6rem;
}
.landing-part ul li img {
  max-width: 34px;
  margin-right: 5px;
}
.landing-part ul {
  line-height: 40px;
}
.landing-part .header_rightbox_bottom {
  display: block;
  background-color: #fff;
  padding: 8px 20px 9px 30px;
  position: absolute;
  top: 50px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 18%);
  font-size: 1.2em;
}
.landing-part .header_rightbox_bottom a {
  color: #0061ab;
}
.landing-part .header_rightbox_bottom:before {
  content: "";
  position: absolute;
  left: -45px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 45px solid white;
  border-left: 45px solid transparent;
  z-index: 0;
}

.topFollowUs > a:hover, .topCallUs > a:hover {
  color: #adf;
}

.footer-social ul {
    margin-top: 0.7em;
    text-align: center;
    margin-bottom: 0.5em;
}
.footer-social ul li a {
  color: #0061ab;
  background: #fff;
  width: 2em;
  height: 2em;
  font-size: 1.2em;
  line-height: 1;
  margin: 0.3em 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 13%);
}
.footer-social ul li a:hover{
    background-color: #0061ab;
    color: #fff;
}

.headerTop.header_area{ position: relative; }
.headerTop.header_area .header_top{ position: relative; background-color: #1993C8;}
.headerTop.header_area .header_top .accessibility_btn {
    color: #1993C8;
    font-size: 14px;
    border: none;
    padding: 11px 24px;
}
.headerTop.header_area .header_top .accessibility_btn img {
    margin-right: 8px;
}
.headerTop.header_area .header_top .rightbox .box {
    border-right: 1px solid rgb(255 255 255 / 38%);
    padding: 0px 18px;
    min-height: 40px;
    display: flex!important;
    align-items: center!important;
}
.headerTop.header_area .header_top .rightbox .box:last-child{ border-left:1px solid rgb(255 255 255 / 38%); }
.headerTop.header_area .header_top .rightbox .box p{ margin-bottom:0; font-size:14px; color:#fff; font-weight:400; }
.headerTop.header_area .header_top .rightbox .box p i {
    margin-right: 6px;
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
}
.headerTop.header_area .header_top .rightbox .box p img{ margin:0 5px; }
.headerTop.header_area .header_top .rightbox .box .footer-social ul{ margin:0; }
.headerTop.header_area .header_top .rightbox .box .footer-social ul li a {
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    font-size: 14px;
    margin: 0;
}
.headerTop.header_area .header_top .rightbox .box .footer-social ul li a:hover{
    background-color: #fff;
    color: #1993C8;
}

@media (max-width:991.98px){
  .headerTop.header_area .header_top {
      display: none;
  }
}

@media (max-width: 767px) {
  ul {
    padding: 0;
  }
  .landing-part .logo {
    margin: 20px 0 35px 3rem;
    max-width: 160px;
  }
 .landing-part .header_rightbox_bottom {
    display: initial;
    top: 70px;
  }
}

@media (max-width:410px){
 .footer-social ul li a {
      font-size: 16px;
  }
}
</style>
<style>

.he-alert {
  direction: rtl;
  text-align: right;
  padding-left: 4rem;
  padding-right: 1rem;
}
.he-alert button.close {
  left: 0;
  right: auto;
}
.he-result-body p {
  direction: rtl;
  text-align: right;
}
div[class*='result-body'] p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.headerTop-links .btn.dropdown-toggle{
  background: #0061ab;
  border: none;
  padding: 0;
  font-size: inherit;
}
.headerTop-links .btn.dropdown-toggle:hover{
  color: yellow;
}
</style>
